@import "../../assets/styles/globals.scss";

.c-logo {
  cursor: pointer;
  position: fixed;
  z-index: 99;
  left: calc(50% - 6vh);
  width: 12vh;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 4vh;

    @media screen and (max-width: 1024px) and (max-height: 768px) {
      width: 5vh;
      min-width: 24px;
    }
  }
}
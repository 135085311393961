@import "../../assets/styles/reset.scss";
@import "../../assets/styles/globals.scss";

html {
  height: -webkit-fill-available;
}

body {
  min-height: 100svh;
  max-height: 100svh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "silva-text", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.25;
}

img {
  -webkit-user-drag: none;
}

a {
  color: $color-black;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

.c-app {
  position: relative;
  user-select: none;
  font-size: 1.8vh;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  &__header,
  &__footer {
    position: fixed;
    left: 0;
    width: 100vw;
  }

  &__header {
    z-index: 11;
    top: 0;
    height: 20vh;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 0) 100%);

    @media screen and (max-width: 768px) {
      height: 15vh;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__footer {
    z-index: 9;
    bottom: 0;
    height: 14vh;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);

    @media screen and (max-width: 768px) {
      height: 20vh;
    }
  }
}
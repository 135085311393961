@import "../../assets/styles/globals.scss";

.c-form {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 20em;

  &--loading {
    pointer-events: none;
    animation: pulse 1.5s infinite;
  }

  &__fields {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: opacity 0.25s ease-in-out;
  }

  &--success &__fields {
    pointer-events: none;
    opacity: 0;
  }

  &__fields input[type="submit"] {
    cursor: pointer;
    appearance: none;
    display: inline-flex;
    margin: 0;
    padding: 0;
    width: auto;
    height: 1.4em;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    font-family: Times, sans;
    color: $color-black;
    font-size: 1em;
    font-weight: normal;
    background: transparent;
    border: none;
    border-radius: 0 !important;
    font-family: var(--font-default);
    font-size: 1em;
  }

  &__message {
    text-align: center;
    opacity: 0;
    margin-bottom: 3em;
    transition: all 0.5s ease-in-out;
  }

  &--undefined &__message,
  &--loading &__message {
    margin-bottom: 0;
    height: 0;
  }

  &--error &__message,
  &--success &__message {
    opacity: 1;
  }

  &--success &__message {
    text-align: center;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
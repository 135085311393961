@import "../../assets/styles/globals.scss";

.c-textarea {
  position: relative;
  margin: 0 0 2em;
  padding: 0;

  &__label {
    pointer-events: none;
    position: absolute;
    top: 0.5em;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    transform-origin: top left;
    font-size: 1em;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }

  &--filled &__label {
    transform: scale(0.75) translateY(-1.9em);
    opacity: 0.5;
  }

  &__field {
    appearance: none;
    resize: none;
    width: 100%;
    min-height: 6em;
    padding: 0.4em 0;
    border: none;
    border-bottom: 1px solid $color-black;
    border-radius: 0 !important;
    background-color: transparent;
    border-radius: none;
    outline: none !important;
  }

  textarea {
    font-family: var(--font-default);
    font-size: 1em;
  }
}
@import "../../assets/styles/globals.scss";

$gallery: '.c-gallery';

.c-gallery {
  pointer-events: none;
  position: relative;
  width: 100vw;
  height: calc(65vh + 5vh);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    top: 0;
    height: auto;
    max-width: 500px;
    max-height: 69vh;
    aspect-ratio: 1 / 1;
  }

  &--root {
    @media screen and (min-width: 769px) {
      top: 25px;
    }
  }

  &--small {
    width: 65vh;
    max-width: 100%;

    #{$gallery}__item__inner {
      display: block;
    }

    #{$gallery}__item__image {
      width: 60%;
      height: 60%;
      object-fit: cover;

      &::before,
      &::after {
        display: block;
      }
    }
  }

  &--ready {
    pointer-events: all;

    #{$gallery}__loading {
      opacity: 0;
    }

    #{$gallery}__inner {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__loading {
    pointer-events: none;
    position: relative;
    top: -5vh;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

    @media screen and (max-width: 768px) {
      top: 0;
    }
  }

  &__inner {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s 0.55s ease-in-out;
  }

  &__images {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    line-height: 1.25;
    animation: fadeOut 0.5s ease-in-out;
    opacity: 0;

    &--active {
      z-index: 9;
      opacity: 1;
      animation: fadeIn 0.5s ease-in-out;
      filter: saturate(1);
    }

    &__inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100%;
      height: 100%;
      text-align: center;
      overflow: scroll;
    }

    &__image {
      position: relative;
      display: inline-block;
      height: 90%;
      width: auto;

      img {
        height: 100%;
        object-fit: cover;
      }

      &::before,
      &::after {
        display: none;
        cursor: pointer;
        content: "";
        position: absolute;
        top: calc(50% - 5vh);
        width: 10vh;
        height: 10vh;
        background-image: url(../../../public/images/arrow-down.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1.5vh 1.5vh;

        @media screen and (max-width: 768px) {
          background-size: 2vh 2vh;
        }
      }

      &::before {
        left: -10vh;
        transform: rotate(90deg);
      }

      &::after {
        right: -10vh;
        transform: rotate(-90deg);
      }
    }

    &__title,
    &__description {
      margin-top: 1em;
    }
  }

  &__pagination {
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    user-select: none;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &__current {
      &::after {
        content: '/';
        margin: 0 0.4vh;
      }
    }
  }

  &__pagination,
  &__navigation {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    @media screen and (min-width: 769px) {

      #{$gallery}__pagination,
      #{$gallery}__navigation {
        opacity: 1;
      }
    }
  }

  &__navigation {
    cursor: pointer;
    position: absolute;
    z-index: 3;
    top: 0;
    width: 50%;
    height: calc(100% - 5vh);
    z-index: 3;

    @media screen and (max-width: 768px) {
      height: 100%;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0
    }
  }
}
@import "../../assets/styles/globals.scss";

$menu: '.c-menu';

.c-menu {
  position: fixed;
  z-index: 10;
  bottom: 3vh;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    bottom: 5vh;
  }

  &--active {
    #{$menu}__list {
      pointer-events: all;
      opacity: 1;
    }

    #{$menu}__backdrop {
      pointer-events: all;
      display: block;
    }

    #{$menu}__toggle {
      pointer-events: none;
      opacity: 0;
    }
  }

  &__toggle {
    cursor: pointer;
    position: fixed;
    z-index: 9;
    bottom: 3vh;
    left: calc(50% - 4vh);
    width: 8vh;
    height: auto;
    display: flex;
    padding: 2vh;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease-in-out;

    @media screen and (max-width: 768px) {
      bottom: 5vh;
    }
  }

  &__backdrop {
    pointer-events: none;
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &__list {
    pointer-events: none;
    position: relative;
    display: flex;
    z-index: 8;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &__item {
    display: inline-flex;
    padding: 2vh 1.8vh;

    a {
      color: $color-black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
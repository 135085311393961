@import "../../assets/styles/globals.scss";

.c-input {
  position: relative;
  margin: 0 0 2em;
  padding: 0;

  &--checkbox {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    left: -5px;
  }

  &__label {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transform-origin: top left;
    font-size: 1em;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }

  &--checkbox &__label {
    cursor: pointer;
    position: relative;
    pointer-events: all;
    display: inline-block;
    padding-left: 0.5em;
    line-height: 1.25;
  }

  &:not(.c-input--checkbox).c-input--filled &__label {
    transform: scale(0.75) translateY(-1.5em);
    opacity: 0.5;
  }

  input {
    font-family: var(--font-default);
    font-size: 1em;
  }

  &--text input,
  &--email input,
  &--password input,
  &--number input {
    appearance: none;
    width: 100%;
    height: 2em;
    padding: 0.4em 0;
    border: none;
    border-bottom: 1px solid $color-black;
    border-radius: 0 !important;
    background-color: transparent;
    border-radius: none;
    outline: none !important;
  }

  &__field[type="checkbox"] {
    position: relative;
    display: inline-block;
    appearance: none;
    width: 11px;
    min-width: 11px;
    height: 11px;
    min-height: 11px;
    padding: 0;
    margin: 0.6vh 0 0 0;
    background-color: $color-grey;
    border-radius: 100% !important;
    outline: none !important;
  }

  &__field[type="checkbox"]:checked {
    background-color: $color-green;
    border-radius: 100% !important;
  }
}
$spacing: 2vw;
$spacing-half: $spacing / 2;
$spacing-double: $spacing * 2;

$color-black: #000;
$color-white: #fff;
$color-grey: #ccc;
$color-green: #1ca92d;

@keyframes fadeIn {
  0% {
    filter: saturate(0);
    opacity: 0;
  }

  100% {
    filter: saturate(1);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    filter: saturate(1);
    opacity: 1;
  }

  100% {
    filter: saturate(0);
    opacity: 0;

  }
}
.c-route {
  position: relative;
  width: 100vw;
  animation: fadeIn 0.5s ease-in-out;

  &--contact,
  &--imprint,
  &--team,
  &--privacy {
    padding-bottom: 20vh;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    margin: 15vh 0 7vh;
    width: 100%;
  }

  h2,
  h3,
  h4 {
    margin: 0 0 1em;
  }

  &--privacy h2 {
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  a {
    width: 100%;
    max-width: 25vw;

    @media screen and (max-width: 1280px) {
      max-width: 40vw;
    }

    @media screen and (max-width: 768px) {
      max-width: 100vw;
    }
  }

  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    @media screen and (max-width: 768px) {
      padding: 0 5vw;
    }
  }

  p {
    margin-bottom: 2vh;
  }

  &__sections {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    &--center {
      justify-content: center;
      min-height: 100svh;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}